@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  p,
  ul {
    @apply mb-2;
  }
  body {
    @apply text-dark;
  }
}

body {
  line-height: 1.4;
}

p {
  min-height: 1.4em;
}

/* * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
